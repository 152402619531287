<template>
  <div>
    <base-content-management 
        title="Partner" 
        contentType="partner" 
        :enableAdd="true" 
        :enableDelete="true" 
        :useCover="true"
        :isStaticTitle="true" 
        :isStaticTitleEditable="true" 
        :useGlobalCta="true"
        :hideContent="true"
    >
    </base-content-management>
  </div>
</template>

<script>
import BaseContentManagement from '../content/BaseContentManagement.vue'

export default {
  title () {
    return `Partner Management`
  },
  components: {
    BaseContentManagement,
  },
}
</script>

<style>

</style>
